<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle :title="title" slot="title" link="/helpContent/applicationList"/>
    <ValidationObserver ref="validator">
      <!-- <div :class="$style.filter">
        <div :class="$style.filterItem">
          <FormSelect
            label="Select Status"
            :items="statusList"
            item-name="Name"
            item-value="Id"
            v-model="filtering.status"
          />
        </div>

        <FormButton
          style="margin-top: 5px"
          type="primary"
          @click="search"
          :rounded="true"
        >
          <Icon type="ios-search" />
        </FormButton>
      </div> -->
      <FormRow style="overflow-y: scroll;max-height: 518px;min-height: 100px;padding: 5px 25px 5px 25px; ">
          <div class="col-md-12"  v-for="(item, index) in eventList" :key="index"  >
            <Card :title="item.Name"  style="margin-top:10px" >
                    
                   <Tasks :items="item"  @openModal="openModal"/>
               
               </Card>
        </div>
            
                  </FormRow>
                  <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton
            type="info"
            @click="
              () => {
                $router.go(-1);
              }
            "
            >Back</FormButton
          >
          <FormButton type="success" @click="previousPage()" v-if="pagination.page>1" >Previous</FormButton>
          <FormButton type="success" @click="nextPage()" v-if="pagination.page <=1" >Next</FormButton>
        </ButtonGroup>
      </div>
    </FormRow>
    </ValidationObserver>
    
    <Popup
      title="Information"
      :value="warningModal.visible"
      @close="() => showWarningModal(false)"
    >
      {{ warningModal.message }}
      <div slot="footer">
        <FormButton
          v-if="warningModal.buttons.cancel"
          @click="() => showWarningModal(false)"
          >Cancel
        </FormButton>
        <FormButton
          v-if="warningModal.buttons.confirm"
          type="success"
          @click="confirmDelete"
          >Confirm
        </FormButton>
        <FormButton
          v-if="warningModal.buttons.ok"
          @click="() => showWarningModal(false)"
          >Ok</FormButton
        >
      </div>
    </Popup>
    <Popup
    width="90%"  
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
    <div style="    padding: 18px;">
      <vueper-slides  fade arrows-outside bullets-outside transition-speed="500" :touchable="false">
  <vueper-slide v-for="(slide, i) in slides" :key="i" :title="slide.title" :content="slide.content" :link="slide.link" :arrows="true"  :image="slide.image" :style="'background-size: contain, cover;'+'background-repeat: no-repeat;'"  >
    <template #bullets="{ bulletIndexes, goToSlide, previous, next, currentSlide }">
  <button
    v-for="(slideIndex, i) in bulletIndexes" :key="i"
    :class="{ 'active': currentSlide === slideIndex }"
    role="tab"
    :aria-label="`Slide ${i + 1}`"
    @click="goToSlide(slideIndex)"
    @keyup.left="previous()"
    @keyup.right="next()">
    <i class="icon">{{ active ? 'check_circle' : 'radio_button_unchecked' }}</i>
  </button>
</template>
  </vueper-slide>
</vueper-slides>
    </div>
    
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import DataTable from "Components/DataTable";
import loadingMixin from "Mixins/loadingMixin";
import { getEventList, removeRow } from "../api";

import validateSelectedRows from "Mixins/validateSelectedRows";
import { ValidationObserver } from "vee-validate";
import  Card from '../Card';
import  Tasks from '../Tasks';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css';
import config from "Config/base";
export default {
  name: "cardView",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    FormWrapper,
    // DataTable,
    PageTitle,
    ValidationObserver,
    Card,
    Tasks,
    VueperSlides,
      VueperSlide,
  },
  data() {
    return {
      filtering: {
        id:0,
        status:null,
      },
    
      sorting: {
        key: "id",
        order: "asc",
      },
      pagination: {
        perPage: 10,
        page: 1,
        total: 0,
      },

      listData: [],
      selectedRows: [],
      showMessage: {
        isVisible: false,
        message: "",
      },
      statusList:[],
      positionId:0,
      title:'Company Events',
      eventList:[],
            slides : [],
    };
  },
  created() {
    this.getDataByFilter();
  },
  methods: {
    openModal(item){
      this.slides=[];
      item.ImagePath.split(",").forEach(e => { 
       this.slides.push({

    // title: 'Slide #1',
    // content: 'Slide 1 content.',
    image:config["imageUrl"]+e.replace(/["']/g, ""),
    link:config["imageUrl"]+e.replace(/["']/g, ""),

       })
      });
      // console.log(this.slides)
      setTimeout(() => {
        this.showMessage.isVisible=true;
      }, 500);

    },
    // add() {
    //   this.$router.push({ path: "/application" });
    // },

    // edit() {
    //   if (this.validateSelectedLength(1)) {
    //     this.$router.push({
    //       path: `/application/edit/${btoa(this.selectedRows[0].Id)}`,
    //     });
    //   }
    // },

    // view() {
    //   if (this.validateSelectedLength(1)) {
    //     this.$router.push({
    //       path: `/application/view/${btoa(this.selectedRows[0].Id)}&${btoa(parseInt(this.positionId))}&${btoa(parseInt(this.selectedRows[0].Status))}`,
    //     });
    //   }
    // },

    previousPage() {
      this.pagination.page=( this.pagination.page) - 1;
      this.pageChange(this.pagination.page);
            },
            nextPage() {
              this.pagination.page=(this.pagination.page) + 1;
              this.pageChange(this.pagination.page);
            },
    view(id,status) {
      console.log("ih",id,status)
      // if (this.validateSelectedLength(1)) {
        this.$router.push({
          path: `/application/view/${btoa(id)}&${btoa(parseInt(this.positionId))}&${btoa(parseInt(status))}`,
        });
      // }
    },

    search() {
      this.pagination.page = 1;
      this.getDataByFilter();
    },
    selectChange(data) {
      this.selectedRows = data;
    },

    sortChange(data) {
      this.sorting.key = data.key;
      this.sorting.order = data.order;
      this.getDataByFilter();
    },
    remove() {
      if (this.validateSelectedLength(1)) {
        if (this.deleteSelectedValue()) {
        }
      }
    },
    confirmDelete() {
      this.showWarningModal(false);
      const data = {
        id: this.selectedRows[0].Id,
      };
      removeRow(data).then((res) => {
        if (res.data[0].status > 1) {
          this.showWarningModal(true);
          this.selectedRows = [];
          this.warningModal.buttons.ok = true;
          this.warningModal.buttons.cancel = false;
          this.warningModal.buttons.confirm = false;
          this.warningModal.message = res.data[0].message;
        } else {
          this.selectedRows = [];
          this.warningModal.buttons.ok = true;
          this.warningModal.buttons.cancel = false;
          this.warningModal.buttons.confirm = false;
          this.showMessage.isVisible = true;
          this.showMessage.message = res.data[0].message;
        }
      });
      this.getDataByFilter();
    },
    getDataByFilter() {
      setTimeout(() => {
          this.$refs.validator.validate().then((result) => {
            if (result) {
              this.showLoader();
              const { key, order } = this.sorting;
              const { perPage, page } = this.pagination;

              const filters = {};
              filters.year = this.filtering.year;
              filters.month = this.filtering.month;
              filters.isHeader = 2;
              const data = {
                filterjson: {
                  filter: [filters],
                  sort: [{ key, order }],
                  paging: [
                    {
                      startIndex: page,
                      pagesize: perPage,
                    },
                  ],
                },
              };
              getEventList(data)
                .then(this.handleResponse)
                .catch(this.handleError);
            }
          });
        }, 50);
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getDataByFilter();
    },
    handleResponse(response) {
      this.hideLoader();
      this.listData = response.data;
      this.pagination.total = response.NumberOfRecords;
      this.eventList=response.data
    },
  },
};
</script>
<style lang="scss" module>
.popupRow {
  padding: 5px 0 15px;
}
.pagination {
  margin: 10px 0 0;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  line-height: 26px;
}

.paginationText {
  margin: 0 20px;
}

.paginationButton {
  cursor: pointer;
  width: 26px;
  text-align: center;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    box-shadow 200ms ease;
}
.paginationButton:hover {
  background: #57a3f3;
  color: #fff;
  &:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }
}

.filter {
  margin: -10px 0 10px;
}

.filterItem {
  display: inline-block;
  margin: 0 15px 15px 0;
}

.filterItemCheckbox {
  flex-basis: auto;
  align-self: flex-start;
  margin-top: 13px;
}
</style>

