<template>
    <div :class="$style.card">
        <!-- <div :class="$style.title">
            {{ title }}
            <div :class="$style.count" v-if="count">
                {{ count }}
            </div>
        </div> -->
        <div :class="$style.body">
            <slot />
        </div>
    </div>
</template>
<script>
    export default {
        name: "Card",
        props: {
            title: String,
            count: Number
            
        }
    }
</script>

<style lang="scss" module>
    .card {
        box-shadow:0 2px 5px 0 #c7c7c7, 0 2px 10px 0 #555;
        // min-height: 240px;
        border-radius: 10px;
    }
    .title {
        // background: #81c3ec;
        // padding: 0px 10px 5px 0px;
        // line-height: 30px;
        // margin-bottom: 0;
        // font-size: 13px;
        // font-weight: 400;
        // box-shadow: 0 2px 5px 0 rgba(0,0,0,.65), 0 2px 10px 0 rgba(0,0,0,.12);
        // text-align: center;
    }
    .body {
        padding: 15px 20px 20px;
        font-size: 12px;
        font-weight: 500;
    }

    .count{
        position: absolute;
        right: 25px;
        top: 4px;
        background: white;
        height: 25px;
        width: 25px;
        border-radius: 50%;
    }
</style>
